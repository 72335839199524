import React,{ useRef } from 'react';
import '../style/style.css';
import { t } from 'i18next';
import Carousel from 'react-bootstrap/Carousel';
import imageSrc from '../img/bg_test.mp4';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import imgCES from '../img/2024_CES.png';
import landImg1 from '../img/land_img1.png';
import landImg2 from '../img/land_img2.png';
import CarouselItem from 'react-bootstrap/esm/CarouselItem';
import { Button } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import emailjs from '@emailjs/browser';


export const telMSG = (str) => {
    let result = ""
    switch (str){
        case "en" :
            result = "Please enter your phone number.";
            break;
        case "ko" :
            result = "연락처를 입력해주세요.";
            break;
        default:
            result = "Please enter your phone number.";
            break;   
    }
    return result
}
export const companyMSG = (str) => {
    let result = ""
    switch (str){
        case "en" :
            result = "Please enter your company name.";
            break;
        case "ko" :
            result = "회사명을 입력해주세요.";
            break;
        default:
            result = "Please enter your company name.";
            break;   
    }
    return result
}
export const emailMSG = (str) => {
    let result = ""
    switch (str){
        case "en" :
            result = "Please enter your E-mail.";
            break;
        case "ko" :
            result = "이메일을 입력해주세요.";
            break;
        default:
            result = "Please enter your E-mail.";
            break;   
    }
    return result
}
export const emailMSG2 = (str) => {
    let result = ""
    switch (str){
        case "en" :
            result = "The email format is incorrect. Please enter again.";
            break;
        case "ko" :
            result = "이메일 형식이 올바르지 않습니다. 다시 입력해주세요.";
            break;
        default:
            result = "The email format is incorrect. Please enter again.";
            break;   
    }
    return result
}
export const msgMSG = (str) => {
    let result = ""
    switch (str){
        case "en" :
            result = "Please write down briefly what you would like to discuss.";
            break;
        case "ko" :
            result = "상담하고 싶은 내용을 간략하게 적어주세요.";
            break;
        default:
            result = "Please write down briefly what you would like to discuss.";
            break;   
    }
    return result
}
export const dateMSG = (str) => {
    let result = ""
    switch (str){
        case "en" :
            result = "Please write down Desired Meeting Schedule.";
            break;
        case "ko" :
            result = "원하시는 미팅 일정을 적어주세요.";
            break;
        default:
            result = "Please write down Desired Meeting Schedule.";     
            break;   
    }
    return result
}
export const msgSendSucess = (str) => {
    let result = ""
    switch (str){
        case "en" :
            result = "Mail has been sent successfully.";
            break;
        case "ko" :
            result = "메일이 전송되었습니다.";
            break;
        default:
            result = "Mail has been sent successfully.";
            break;   
    }
    return result
}
export const msgSendFail = (str) => {
    let result = ""
    switch (str){
        case "en" :
            result = "Mail delivery failed. please try again.";
            break;
        case "ko" :
            result = "메일 전송에 실패했습니다. 다시 시도해 주세요.";
            break;
        default:
            result = "Mail delivery failed. please try again.";
            break;   
    }
    return result
}

/**
 * xs, extra-small: 0px
    sm, small: 600px
    md, medium: 960px
    lg, large: 1280px
    xl, extra-large: 1920px
 */
    

export default function MainTest() {

    // const media760 = useMediaQuery('(max-width:760px)');

    // const classes = useStyles();
    // const media720 = useMediaQuery('(max-width:720px)');
    const media960 = useMediaQuery('(max-width:960px)');

    sessionStorage.setItem('imgOn', false);

    const useStyles = makeStyles((theme) => ({

        img_ces: {
            width : "90px",
            height : "130px",
            marginTop : "75px",
            marginLeft : "25px",
            zIndex: 99,
            position: "fixed",
            
            [theme.breakpoints.down('md')]: {
                width : "60px",
                height : "85px",
                marginTop : "75px",
                marginLeft : "25px",
            },
            [theme.breakpoints.down('sm')]: {
                width : "45px",
                height : "65px",
                marginTop : "70px",
                marginLeft : "15px",
                
            },
        },
        carousel_box: { //1920px ~
            background:'#0F274199',
            width:'1400px',
            height:'650px', 
            maxWidth:'1400px',
            alignItems:'center',
            display: 'flex',
            [theme.breakpoints.down('lg')]: { // 1280~1920px
                Width:'1200px',
                maxWidth:'1200px',
                height:'650px', 
            },

            [theme.breakpoints.down('md')]: { // 960~1280px
                Width:'800px',
                maxWidth:'800px',
                height:'500px', 
            },
            [theme.breakpoints.down('sm')]: { // 600~960px
                maxWidth:'600px',
                height:'500px', 
            },
            [theme.breakpoints.down('xs')]: {
                width:'100vw',
                height:'100vh',
            }
        },
        langing_box: {
            width:'40%',
            marginLeft:'auto',
            marginRight:'0',
            
            [theme.breakpoints.down('sm')]: {
                width:'60%',
                marginRight:'auto',
            },
            [theme.breakpoints.down('xs')]: {
                width:'75%',
            }
        },  
        land_header: {
            fontSize:'35px', 
            [theme.breakpoints.down('lg')]: {
                fontSize:'30px', 
            },
            [theme.breakpoints.down('md')]: {
                fontSize:'21.5px', 
            },
            [theme.breakpoints.down('sm')]: {
                fontSize:'21.5px', 
                textAlign:'center',
            },
            [theme.breakpoints.down('xs')]: {
                fontSize:'27px', 
            }

        },
        land_tit: {
            fontSize:'86px',
            width:'100%',
            [theme.breakpoints.down('lg')]: {
                fontSize:'70px', 
            },
            [theme.breakpoints.down('md')]: {
                fontSize:'54px', 
            },
            [theme.breakpoints.down('sm')]: {
                fontSize:'54px', 
                textAlign:'center',
            },
            [theme.breakpoints.down('xs')]: {
                fontSize:'50px', 
            }

        },
        land_content: {
            fontSize:'14px',
            [theme.breakpoints.down('lg')]: {
                fontSize:'12px', 
            },
            [theme.breakpoints.down('md')]: {
                fontSize:'10px',
            },
            [theme.breakpoints.down('sm')]: {
                fontSize:'10px', 
            },
            [theme.breakpoints.down('xs')]: {
                fontSize:'13px', 
            }
        },
        land_btn: {
            border:"solid 2px white", 
            borderRadius:'0px',
            color:'#fff', 
            fontWeight:'300', 
            fontSize:'22px',
            width:'100%',
            height:'90px',
            display:'flex',
            marginBottom:'18px',
            marginLeft:'auto',
            marginRight:'auto',
            [theme.breakpoints.down('lg')]: {
                maxHeight:'72px',
                fontSize:'17px',
                marginBottom:'14px',
            },
            [theme.breakpoints.down('md')]: {
                maxHeight:'52px',
                fontSize:'14px',
            },
            [theme.breakpoints.down('sm')]: {
                maxHeight:'52px',
                fontSize:'17px',
                maxWidth:'418px'
            },
            [theme.breakpoints.down('xs')]: {   
                fontSize:'20px',
                maxWidth:'266px'
            }
        },
        land_img_box: {
            width:'40%',
            marginTop:'auto',
            marginBottom:'auto',
            marginRight:'auto',
            marginLeft:'0',
            [theme.breakpoints.down('md')]: {
                width: '50%',
                marginRight:'0',
                marginLeft:'0',
            },
            [theme.breakpoints.down('sm')]: {
                width :'30%',
                
            },
        
        },
        land_img: {
            width: '100%',
            [theme.breakpoints.down('lg')]: {
                width: '100%'
            },
            [theme.breakpoints.down('md')]: {
                width: '80%'
            },
            [theme.breakpoints.down('sm')]: {
                width :'50%',
                
            },
        },

        
        land_meet_box: {
            width:'55%',
            marginLeft:'auto',
            marginRight:'auto',
            [theme.breakpoints.down('md')]: {
                width: '70%'
            },
            [theme.breakpoints.down('sm')]: {
                width :'70%',
            },
            [theme.breakpoints.down('xs')]: {
                // width :'70%',
            },
        },
        meet_header: {
            fontSize:'35px', 
            lineHeight:'35px',
            textAlign:'center',
            
            [theme.breakpoints.down('lg')]: {
                fontSize:'30px', 
            },
            [theme.breakpoints.down('md')]: {
                fontSize:'22px', 
                lineHeight:'20px',
            },
            [theme.breakpoints.down('sm')]: {
                fontSize:'22px', 
                textAlign:'center',
            },
            [theme.breakpoints.down('xs')]: {
                fontSize:'29px', 
                lineHeight:'30px',
            }
        },
        meet_content: {
            fontSize:'16px',
            lineHeight:'24px',
            textAlign:'center',
            [theme.breakpoints.down('lg')]: {
                fontSize:'16px',
                lineHeight:'22px',
            },
            [theme.breakpoints.down('md')]: {
                fontSize:'12px',
                lineHeight:'17px',
            },
            [theme.breakpoints.down('sm')]: {
                fontSize:'12px', 
                lineHeight:'17px',
            },
            [theme.breakpoints.down('xs')]: {
                fontSize:'12px', 
                lineHeight:'18px',
            }
        },
        meet_btn: {
            fontSize:'20px',
            lineHeight:'23.87px',
            width:'231px',
            height:'43px',
            marginLeft:'auto',
            marginRight:'auto',
            display:'block',
            borderRadius:'8px',
            [theme.breakpoints.down('lg')]: {
                width:'231px',
                height:'43px',
                lineHeight:'23.87px',
                fontSize:'15px',
                borderRadius:'8px',
            },
            [theme.breakpoints.down('md')]: {
                width:'118px',
                height:'29px',
                lineHeight:'13.13px',
                fontSize:'11px',
                borderRadius:'4px',
            },
            [theme.breakpoints.down('sm')]: {
                width:'118px',
                height:'30px',
                lineHeight:'13.13px',
                fontSize:'11px',
            },
            [theme.breakpoints.down('xs')]: {   
                width:'145px',
                height:'35px',
                lineHeight:'21.48px',
                fontSize:'14px',
            }
        },
        
   }));
   const classes = useStyles();

//    const dateAlert = dateMSG(t('lang'));
    const telAlert = telMSG(t('lang'));
    const companyAlert = companyMSG(t('lang'));
    const emailAlert = emailMSG(t('lang'));
    const emailAlert2 = emailMSG2(t('lang'));
    const msgAlert = msgMSG(t('lang'));
    const dateAlert = dateMSG(t('lang'));
    const sendSucess = msgSendSucess(t('lang'));
    const sendFail = msgSendFail(t('lang'));

    const form = useRef();

    const carouselRef = useRef(null);

    // Function to handle the previous button click
    const handlePrevClick = () => {
        if (carouselRef.current) {
            carouselRef.current.prev();  // This triggers the previous slide in the carousel
        }
    };
    const handleNextClick = () => {
        if (carouselRef.current) {
            carouselRef.current.next();  // This triggers the previous slide in the carousel
        }
    };

    const sendEmail = (e) => {
        e.preventDefault();

        
        const regex = /^[0-9a-zA-Z]([-_]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;
        // const format = /^(19[7-9][0-9]|20\d{2})-(0[0-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/;
        
        if (form.current.company.value.trim() === "") {
            alert(companyAlert)
        } else if (form.current.tel.value.trim() === "") {
            alert(telAlert)
        } else if (form.current.email.value.trim() === "" ) {
            alert(emailAlert)
        } else if (form.current.message.value.trim() === "") {
            alert(msgAlert)
        } else if (regex.test(form.current.email.value.trim()) === false) {
            alert(emailAlert2)
        } else if (form.current.date.value.trim() === false) {
            alert(dateAlert)
        } else {
            emailjs.sendForm('service_usedakd', 'template_nn2iieh', form.current, 'QQ4-PXkBD0XhmbLhX')
            .then((result) => {
                if (result.text === "OK") {
                    alert(sendSucess)

                    // form.current.name.value = ""
                    form.current.tel.value = ""
                    form.current.company.value = ""
                    form.current.email.value = ""
                    form.current.date.value = ""
                    form.current.message.value = ""

                } else 
                    alert(sendFail)
            }, (error) => {
                alert(sendFail)
            });
            
        }
    };

    return (
        <div>
            <div>
                <img src={imgCES} className={classes.img_ces} alt='2024 CES 혁신상 이미지'/>
            </div>
            <div className="main-bg-wrap">
                <video className="bg-video__content" autoPlay muted loop playsInline>
                <source src={imageSrc} type='video/mp4'/>
                Your browser is not supported!
                </video>

                <div >
                    <Carousel ref={carouselRef} className={classes.carousel_box}>
                        <Carousel.Item >
                            {media960 === false ?
                            <div className="landing justify-content-center d-flex center">
                                <div className={classes.langing_box}>
                                    <h3 className={classes.land_header}>{t('main_land1_header')}</h3>
                                    <h1 className={classes.land_tit}>{t('main_land1_title')}</h1>
                                    <p className={classes.land_content}>{t('main_land1_content')}</p>
                                    
                                    <Button variant="primary" onClick={handlePrevClick} className={classes.land_btn}>{t('main_land_reserve')}</Button>
                                    <NavLink to='contact'><Button className={classes.land_btn}>{t('main_land_contact')}</Button></NavLink>
                                </div>
                                <div className={classes.land_img_box}>
                                    <img src={landImg1} className={classes.land_img} style={{marginLeft:'10px'}}></img>
                                </div>
                            </div>  
                            :
                            <div className="landing justify-content-center" id='reserveBtn'>
                                <div className="justify-content-center d-flex center">
                                    <div className={classes.langing_box}>
                                        <h3 className={classes.land_header}>{t('main_land1_header')}</h3>
                                        <h1 className={classes.land_tit}>{t('main_land1_title')}</h1>
                                        <p className={classes.land_content}>{t('main_land1_content')}</p>
                                        
                                        <Button variant="primary" onClick={handlePrevClick} className={classes.land_btn}>{t('main_land_reserve')}</Button>
                                        <NavLink to='contact'><Button className={classes.land_btn}>{t('main_land_contact')}</Button></NavLink>
                                        
                                    </div>
                                </div>  
                            </div>
                            }
                        </Carousel.Item>
                        <Carousel.Item >
                            {media960 === false ?
                            <div className="landing justify-content-center d-flex center">
                                <div className={classes.langing_box}>
                                    <h3 className={classes.land_header}>{t('main_land2_header')}</h3>  
                                    <h1 className={classes.land_tit}>{t('main_land2_title')}</h1>
                                    <p className={classes.land_content}>{t('main_land2_content')}</p>
                                    
                                    
                                    <Button variant="primary" onClick={handleNextClick} className={classes.land_btn}>{t('main_land_reserve')}</Button>
                                    <NavLink to='contact'><Button className={classes.land_btn}>{t('main_land_contact')}</Button></NavLink>
                                    
                                </div>
                                <div className={classes.land_img_box}>
                                    <img src={landImg2} className={classes.land_img}></img>
                                </div>
                            </div>  
                            :
                            <div className="landing justify-content-center" id='reserveBtn'>
                                <div className="landing justify-content-center d-flex center">
                                    <div className={classes.langing_box}>
                                        <h3 className={classes.land_header}>{t('main_land2_header')}</h3>  
                                        <h1 className={classes.land_tit}>{t('main_land2_title')}</h1>
                                        <p className={classes.land_content}>{t('main_land2_content')}</p>
                                        
                                        <Button variant="primary" onClick={handleNextClick} className={classes.land_btn}>{t('main_land_reserve')}</Button>
                                        <NavLink to='contact'><Button className={classes.land_btn}>{t('main_land_contact')}</Button></NavLink>
                                
                                        
                                    </div>
                                </div>  
                            </div>
                            }
                        </Carousel.Item>
                        <Carousel.Item>
                            
                            <div className="meeting justify-content-center m-auto">
                                <div className={classes.land_meet_box}>
                                    <h1 className={classes.meet_header}>{t('main_land3_header')}</h1>
                                    <p className={classes.meet_content}>{t('main_land3_content1')}<br/>
                                    {t('main_land3_content2')}<br/> 
                                    {t('main_land3_content3')}</p>

                                    <form ref={form} onSubmit={sendEmail} className='form' >
                                        <div>
                                            <input autoFocus={true} type="text" name="tel" className='meet_field form-control ' placeholder={t('contact_form_tel')}  required />
                                        </div>
                                        <div>
                                            <input type="text" name="company" className='form-control meet_field ' placeholder={t('contact_form_company')}  required />
                                        </div>
                                        <div>
                                            <input type="email" name="email" className='form-control meet_field ' placeholder={t('contact_form_email')}  required />
                                        </div>
                                        <div>
                                            <input type="text" name="date" className='form-control meet_field ' placeholder={t('contact_form_date')} required />
                                        </div>
                                        <div>
                                            <textarea name="message" className='form-control meet_msg ' placeholder={t('contact_form_cesmsg')}  required />
                                        </div>
                                        <input type="submit" value={t('main_land3_send')} className={classes.meet_btn} />
                                    </form>
                                </div>
                            </div>  
                        </Carousel.Item>
                    </Carousel>

                </div>


                


            {/* 241210전 기존 문구 */}
            {/* app.css 에서 fixed 주석 해제해야함 */}
                {/* <Carousel >
                    <Carousel.Item>
                        {media720 === false ?
                        <div className="content justify-content-center">
                            <span>{t('main_top1-1')}</span>
                            <span>{t('main_top1-2')}</span>
                            <span>{t('main_top1-3')}</span>
                            <span className='mt-10'>{t('main_top1-4')}</span>
                            <span>{t('main_top1-5')}</span>
                        </div>  
                        :
                        <div className="content justify-content-center">
                            <span>{t('main_top1-1')} {t('main_top1-2')} {t('main_top1-3')}</span>
                            <span className='mt-10'>{t('main_top1-4')} {t('main_top1-5')}</span>
                        </div>  
                        }
                    </Carousel.Item>
                    <Carousel.Item>
                        {media720 === false ?
                        <div className="content justify-content-center">
                            <span>{t('main_top2-1')}</span>
                            <span>{t('main_top2-2')}</span>
                            <span>{t('main_top2-3')}</span>
                            <span className='my-10'>{t('main_top2-4')}</span>
                            <span>{t('main_top2-5')}</span>
                            <span>{t('main_top2-6')}{t('main_top2-7')}</span>
                        </div>
                        :
                        <div className="content justify-content-center">
                            <span>{t('main_top2-1')} {t('main_top2-2')} {t('main_top2-3')}</span>
                            <span className='my-10'>{t('main_top2-4')} {t('main_top2-5')} {t('main_top2-6')} {t('main_top2-7')}</span>
                        </div>
                        }
                    </Carousel.Item>
                    <Carousel.Item>
                        {media720 === false ?
                        <div className="content justify-content-center">
                            <span>{t('main_top3-1')}</span>
                            <span>{t('main_top3-2')}</span>
                            <span>{t('main_top3-3')}</span>
                            <span className='mt-10'>{t('main_top3-4')}</span>
                            <span>{t('main_top3-5')}</span>
                        </div>
                        :
                        <div className="content justify-content-center">
                            <span>{t('main_top3-1')} {t('main_top3-2')} {t('main_top3-3')}</span>
                            <span className='mt-10'>{t('main_top3-4')} {t('main_top3-5')}</span>
                        </div>
                        }
                    </Carousel.Item>
                </Carousel> */}
                
            </div>
        </div>
    )
}