import React, { useEffect, useState } from 'react';
import logo from '../img/22_logo_white.svg';
import { makeStyles } from '@material-ui/core/styles';
import { Link, NavLink } from 'react-router-dom';
// import styles from '../style/Bar.module.css';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import '../style/test.css';
import '../App.css';
import ic_lang from '../icon/ic_lang.png';
import i18next from 'i18next';

/**
 * xs, extra-small: 0px
    sm, small: 600px
    md, medium: 960px
    lg, large: 1280px
    xl, extra-large: 1920px
 */

const activeStyle = {
    fontWeight: 800,
    opacity: 1,
    }

const useStyles = makeStyles((theme) => ({
    
    // mainBox: {
    //     maxWidth: "1200px", 
    //     width: "70%",
    //     margin: "auto",
    //     marginTop: "5%",
    //     background: "#f4f4f4",
    //     fontFamily: "PretendardM",
        
    //     [theme.breakpoints.down('md')]: {
    //         width: "70%",
    //         textAlign: "center",
    //         flexDirection: "column",
    //     },
    //     [theme.breakpoints.down('sm')]: {
    //         width: "70%",
    //         textAlign: "center",
    //         margin: "auto",
    //         flexDirection: "column",
    //     },
    //     [theme.breakpoints.down('xs')]: {
    //         flexDirection: "column",
    //         textAlign: "center",
    //         width: "90%",
    //         margin: "auto",
    //     },
    // },
    // logoStyle: {
    //     marginLeft: "40px",
    //     transition: "all .75s",
    //     [theme.breakpoints.down('xs')]: {
    //         marginLeft: "20px",
    //     },
    // },
    logoMediaStyle: {
        transition: "all .75s",
    },
    // menuStylebtn: {
    //     color: "white",
    //     fontSize: "22px",
    //     fontFamily: "PretendardM",
    //     fontWeight: "600",
    // },
    // menuStylebtnChange: {
        
    //     fontSize: "22px",
    //     fontFamily: "PretendardM",
    //     fontWeight: "600",
    // },
    // md_top: {
    //     marginTop: "25px",
    //     display: "flex",
    // },
    md_0: {
        marginTop: "15px",
        display: "flex",
        color: "white",
    },
    // md_last: {
    //     marginTop: "15px",
    //     paddingbottom: "10px",
    // },
    menuListStyle: {
        
        fontSize: "0.9rem",
        fontWeight: "200",
        marginTop: "5px",
        paddingLeft: "0",
        textDecoration: "none",
    },
    menuListNONHOME: {
        
        fontSize: "15px",
        marginTop: "5px",
        fontWeight: "200",
        paddingLeft: "0",
        textDecoration: "none",
        [theme.breakpoints.down('sm')]: {
            color: "white",
        },
    },
    menuIcon: {
        position: "fixed",
        right: "0",
        marginRight: "78px",
    },
    menuSpan: {
        display: "block",
        position: " absolute",
        width: "37.5px",
        height: "3px",
        background: "#fff",
        transition: "all .35s",
        right: "0",
    },
    menuSpanChange: {
        display: "block",
        position: " absolute",
        width: "37.5px",
        height: "2px",
        background: "#fff",
        transition: "all .35s",
        right: "0",
    },
    // vertical: {
    //     minWidth: "17rem",
    //     width: "17rem",
    //     height: "300vh",
    //     position: "fixed",
    //     backgroundColor: "#252525",
    //     top: "0",
    //     right: "0",
    //     marginRight: "-17rem",

    // },
    verticalMenu: {
        listStyle: "none",
        width: "17rem",
        marginRight: "-17rem",
        marginTop: "58px",
        marginLeft: "15px",
    },
    label: {
        display: "block",
        width: "55px",
        height: "35px",
        position: "relative",
        cursor: "pointer",
        transition: "all 0.47s",
    }, 
      
}));

export default function Bar({ sel, onBack, boool, check }) {
    let classes = useStyles();

    let [deep, setDeep] = useState(logo);
    let [menuList, setMenuList] = useState(classes.menuListStyle);
    let [mediaMenu, setmediaMenu] = useState(classes.menuSpan);
    let [checked, setChecked] = useState(false);


    useEffect(() => {

        if (!boool) {
            setMenuList(classes.menuListNONHOME);
            setmediaMenu(classes.menuSpanChange);
        } else {
            setDeep(logo);
            setMenuList(classes.menuListStyle);
            setmediaMenu(classes.menuSpan);
        }
    }, [boool, classes]);

    useEffect(() => {
        const vert = document.getElementById("menuicon");
        if (vert != null) {
            window.scrollTo({
                top: "0",
                behavior: "smooth",
            })
            vert.checked = false;
        }

    }, [check]);

    const media425 = useMediaQuery('(max-width:426px)');
    const media960 = useMediaQuery('(max-width:960px)');
    
    const clickHandler = (lang) =>{
        i18next.changeLanguage(lang);
    }
    
    useEffect(() => {
        if (checked) {
            setChecked(classes.menuToggleChange);
        } else {
            setChecked(classes.menuToggle);
        }
    }, [checked, classes]);

    return (
        <header className="mainBar" >


            <div className="logoMain">

                <div>
                {(media425) ? 
                    <div className={classes.logoMediaStyle}>
                        <Link to='/' onClick={onBack}> <img id="logoImg" src={deep} alt="Logo"></img></Link>
                    </div>  
                    : 
                    <div className={classes.logoMediaStyle}>
                        <Link to='/' onClick={onBack}> <img id="logoImg" src={deep} alt="Logo"></img></Link>
                    </div>  
                }
                </div>
                {media960 === true ?
                <div >
                    <div>
                        <input type="checkbox" id="menuicon" />
                        <label htmlFor="menuicon">
                            <span className={mediaMenu}></span>
                            <span className={mediaMenu}></span>
                            <span className={mediaMenu}></span>
                        </label>
                        <div className="vertical">
                            
                            <ul className={classes.verticalMenu}>

                                <NavLink to='/' exact={true} className={classes.md_0} activeStyle={classes.activeStyle}><p className='menu_font' onClick={sel} >Home</p></NavLink> 
                                <NavLink to='service' className={classes.md_0} activeStyle={classes.activeStyle}><p className='menu_font ' onClick={sel}>Service</p></NavLink>
                                <NavLink to='technology' className={classes.md_0} activeStyle={classes.activeStyle}><p className='menu_font ' onClick={sel}>Technology</p></NavLink>
                                <NavLink to='company' className={classes.md_0} activeStyle={classes.activeStyle}><p className='menu_font' onClick={sel}>Company</p></NavLink>
                                <NavLink to='media' className={classes.md_0} activeStyle={classes.activeStyle}><p className='menu_font' onClick={sel}>Media</p></NavLink>
                                <NavLink to='contact' className={classes.md_0} activeStyle={classes.activeStyle}><p className='menu_font' onClick={sel}>Contact</p></NavLink>

                                <div className='mt-3'> 
                                    <Link to='#' className={menuList}><span  onClick={()=>clickHandler("ko")}><b className="lang_font">한국어</b></span></Link>
                                    <span className='mx-1' style={{color:'white',}}> / </span>
                                    <Link to='#' className={menuList}><span  onClick={()=>clickHandler("en")}><b className="lang_font">English</b></span></Link>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
                
                :
                <div data-aos="fade-up" className='menu'> 
                    <NavLink to='/' exact={true} className="items" activeStyle={activeStyle}><p className='m-0' onClick={sel} >Home</p></NavLink> 
                    <NavLink to='service' className="items" activeStyle={activeStyle}><p className='m-0' onClick={sel}>Service</p></NavLink>
                    <NavLink to='technology' className="items" activeStyle={activeStyle}><p className='m-0' onClick={sel}>Technology</p></NavLink>
                    <NavLink to='company' className="items" activeStyle={activeStyle}><p className='m-0' onClick={sel}>Company</p></NavLink>
                    <NavLink to='media' className="items" activeStyle={activeStyle}><p className='m-0' onClick={sel}>Media</p></NavLink>
                    <NavLink to='contact' className="items" activeStyle={activeStyle}><p className='m-0' onClick={sel}>Contact</p></NavLink>

                    <span style={{ color: "#DBDBDB", opacity: "0.6",}}>|</span>
                    {/* 영/한 버튼 */}
                    <nav id="menu_lang">
                        <ul>
                            <li>
                                <img src={ic_lang} className='ic_lang' alt='Logo'/>
                                <ul id="menu_block" >
                                    <Link to='#'><li className='py-1 px-2 text-left' onClick={()=>clickHandler("ko")}>한국어</li></Link>
                                    <Link to='#'><li className='py-1 px-2 text-left' onClick={()=>clickHandler("en")}>English</li> </Link>
                                </ul>
                            </li>
                        </ul>
                    </nav>
                
                </div>
                }
            </div>

        </header >
    )
}