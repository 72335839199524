import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import langEn from './lang.en.json';
import langKo from './lang.ko.json';
 
const resource = {

    en: {
        translations: langEn
    },
    ko: {
        translations: langKo
    }
}
 
i18n
    .use(initReactI18next)
    .init({
        resources: resource,
        // 초기 설정 언어
        lng: "en",
        fallbackLng: "en",
        debug: false,
        defaultNS: 'translations',
        ns: 'translations',
        keySeparator: false,
        interpolation: {
            escapeValue: false
        }
    });

//브라우저 DB에 기록된 언어가 있으면 해당 언어를 불러온다.
let language = localStorage.getItem("language");
if(language!==null)
i18n.changeLanguage(language);

//국가 코드를 변경하는 함수
export function changeLanguage() {
  i18n.changeLanguage(i18n.language === "ko" ? "en" : "ko");
  //브라우저 DB에 기록
  localStorage.setItem("language", i18n.language);
}
 
export default i18n;