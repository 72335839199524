import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { t } from 'i18next';
import service1 from '../icon/ic_tag.png';
import olppImg from '../img/sc_img_olpp.png';
import sdgImg from '../img/sc_img_sdg.png';


/**
 * xs, extra-small: 0px
    sm, small: 600px
    md, medium: 960px
    lg, large: 1280px
    xl, extra-large: 1920px
 */
const useStyles = makeStyles((theme) => ({

    container: {
        maxWidth: "1200px",
        width: "70%",
        margin: "auto",
        marginTop: "80px",
        marginBottom: "150px",
        fontFamily: "NanumSquare",
        lineHeight: "1.88",
        
        [theme.breakpoints.down('md')]: { 
            width: "80%",
        },
        [theme.breakpoints.down('xs')]: { 
            width: "90%",
        },
    },
    container__mainbox: {

        maxWidth: "85%",
        width: "100%",
        margin:"auto",
        marginTop: "75px",

        [theme.breakpoints.down('md')]: { 
            maxWidth:"100%",
        },
        [theme.breakpoints.down('xs')]: { 
            width: "90%",
            textAlign: "left",
        },
    },
}));

export default function Service() {
    const classes = useStyles();
    sessionStorage.setItem('imgOn', false);


    return (
        <div >
            <div className="sub-bg-wrap-svc sub-bg-wrap fs_32">
                <div className="content">
                    <span>{t('service')}</span>
                    
                </div>
            </div>
            <div className={classes.container} >
                
                <div className={classes.container__mainbox} data-aos="fade-up" >
                    
                    <div data-aos="fade-up" className='svc_block'>
                        <h4 className='svc'>
                            <img src={service1} className='ic_svc me-3' alt='서비스 태그'/>
                            
                            <a rel="noreferrer" href='https://www.ksponco.or.kr/olympicpark/finedust' target='_blank' title='새 창으로 이동' >
                            <span className='svc_txt'>{t('svc_olpp')}</span>
                            </a> 
                        </h4>
                        <a rel="noreferrer" href='https://www.ksponco.or.kr/olympicpark/finedust' target='_blank' >
                        <img width="100%" className='svc_img' src={olppImg} title='새 창으로 이동' alt='올림픽공원 미세먼지 서비스 화면'/>
                        </a>
                    </div>

                    
                    <div data-aos="fade-up" className='svc_block mt-10 mb-12'>
                        <h4 className='svc'>
                            <img src={service1} className='ic_svc me-3' alt='서비스 태그'/>
                            
                            <a rel="noreferrer" href='https://deepvisions.co.kr/acs-public/sdg/seongdong-finedust/' target='_blank' title='새 창으로 이동' >
                            <span className='svc_txt'>{t('svc_sdg')}</span>
                            </a> 
                        </h4>
                        <a rel="noreferrer" href='https://deepvisions.co.kr/acs-public/sdg/seongdong-finedust/' target='_blank' >
                        <img width="100%" className='svc_img' src={sdgImg} title='새 창으로 이동' alt='성동구 미세먼지 서비스 화면'/>
                        </a>
                    </div>
                </div>        
            </div >
        </div>
    )
}