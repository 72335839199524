import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { t } from 'i18next';
import about_img1 from '../img/img_about1.png';
import about_img2 from '../img/img_about2.png';
import about_img3 from '../img/img_about3.png';


import ic_award from '../icon/ic_award.png';

import partner1 from '../icon/ic_partner_1.png';
import partner2 from '../icon/ic_partner_2.png';
import partner3 from '../icon/ic_partner_3.png';
import partner4 from '../icon/ic_partner_4.png';
import partner5 from '../icon/ic_partner_5.png';
import partner6 from '../icon/ic_partner_6.png';
import partner7 from '../icon/ic_partner_7.png';
import partner8 from '../icon/ic_partner_8.png';
import partner9 from '../icon/ic_partner_9.png';
import partner10 from '../icon/ic_partner_10.png';

export const historyImg1 = (str) => {
    let result = ""
    switch (str) {
        case "en" :
            result = require(`../img/history_en.svg`).default;
            break;
        case "ko" :
            result = require(`../img/history_ko.svg`).default;
            break;
        default:
            result = require(`../img/history_en.svg`).default;
    }

    return result
}

export const historyImg_960 = (str) => {
    let result = ""
    switch (str){
        case "en" :
            result = require(`../img/history_960_en.svg`).default;
            break;
        case "ko" :
            result = require(`../img/history_960_ko.svg`).default;
            break;
        default:
            result = require(`../img/history_960_en.svg`).default;
    }

    return result
}

/**
 * xs, extra-small: 0px
    sm, small: 600px
    md, medium: 960px
    lg, large: 1280px
    xl, extra-large: 1920px
 */
const useStyles = makeStyles((theme) => ({

    mainBox: {
        maxWidth: "1200px",
        width: "70%",
        margin: "auto",
        fontFamily: "NanumSquare",
        lineHeight: "1.88",
        [theme.breakpoints.down('xs')]: { 
            width: "90%",
            margin: "auto",
            textAlign: "center",

        },
    },
    teamBox: {
        maxWidth: "1200px",
        width: "80%",
        margin: "auto",
        fontFamily: "NanumSquare",
        lineHeight: "1.88",
        [theme.breakpoints.down('xs')]: { 
            width: "90%",
            margin: "auto",
            textAlign: "center",
        },
    },
    mainBox__subHeader: {
        maxWidth: "65%",
        width: "100%",
        margin:"auto",
        fontWeight: "200",
        [theme.breakpoints.down('md')]: {
            maxWidth: "90%",
        },
        [theme.breakpoints.down('xs')]: {
            textAlign: "left",
            maxWidth: "100%",
            
        },
    },
    content__three: {
        maxWidth: "25%",
        width: "100%",
        [theme.breakpoints.down('xs')]: { 
            maxWidth: "100%",
        },
    },
    content__seven: {
        maxWidth: "70%",
        width: "100%",
        marginLeft: "4.5rem",
        [theme.breakpoints.down('xs')]: { 
            maxWidth: "100%",
            marginLeft: "0",
        },
    },
    inlineBox__third: {
        maxWidth: "55%",
        width: "30%",
        margin: "0",
        marginLeft: "auto",
        marginRight: "auto",
        [theme.breakpoints.down('md')]: {
            width: "70%",
            margin: "auto",
            marginBottom: "2%",
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: "100%",
            margin: "auto",
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: "100%",
            textAlign: "center",
        },
    },
    imgSource: {
        width: "100%",
        objectFit: "contain",
        [theme.breakpoints.down('md')]: {
            margin: "auto",
        },
        [theme.breakpoints.down('sm')]: {
            margin: "auto",
        },
    },
    imgBox: {
        backgroundColor: "#F7F7F7",
        borderRadius: "8px",
        [theme.breakpoints.down('md')]: {
            marginTop: "50px",
        },
    },
    content__detail__flex: {
        display: "flex",
        [theme.breakpoints.down('md')]: {
            display: "block",
        },
    },
    imgHistory: {
        maxWidth: "1200px",
        objectFit: "contain",
        margin: "auto",
        [theme.breakpoints.down('md')]: {
            maxWidth:"960px",
            width:"75%",
            margin: "auto",
            marginTop: "90px",
            marginBottom: "10px",
        },
        [theme.breakpoints.down('sm')]: {
            width:"55%",
        },
        [theme.breakpoints.down('xs')]: {
            width:"95%",
        },
    },
    mainBox__back: {
        maxWidth: "1200px",
        width: "70%",
        margin: "auto",
        paddingTop: "5%",
        paddingBottom: "50px",
        fontFamily: "NanumSquare",
        [theme.breakpoints.down('md')]: {
            width: "80%",
            textAlign: "center",
            flexDirection: "column",
        },
        [theme.breakpoints.down('sm')]: {
            
            textAlign: "center",
            margin: "auto",
            flexDirection: "column",
        },
        [theme.breakpoints.down('xs')]: {
            width: "100%",
            flexDirection: "column",
            textAlign: "center",
            margin: "auto",
        },
    },
}));

export default function Introduction() {
    const classes = useStyles();
    const media720 = useMediaQuery('(max-width:720px)');
    const media960 = useMediaQuery('(max-width:960px)');

    sessionStorage.setItem('imgOn', false);

    const img_history1 = historyImg1(t('lang'));
    const img_history_960 = historyImg_960(t('lang'));

    return (
        
        <div>
            <div className="sub-bg-wrap-about sub-bg-wrap fs_32">
                <div className="content">
                    <span>{t('company_tit')}</span>
                </div>
            </div>
            <div className='py-13' data-aos="fade-up">
                <div className={classes.mainBox}>

                    {/* about us */}
                    <div className='text-center'>
                        <h1 className='contet_tit'>{t('company_about0')}</h1>
                        <span className='contet_sub'> {t('company_about1')} </span>
                        <p className='about_txt'>{t('company_about2')}<br/>{t('company_about3')}</p>
                    </div>

                    <div>
                        <div className={classes.content__detail__flex} >
                            <div className={classes.inlineBox__third}>
                                <div className={classes.imgBox} >
                                    <img className={classes.imgSource} src={about_img1} alt="about us"  /> 
                                </div>
                                <div>
                                    <p className='about_subtit'><b> {t('company_about4')} </b></p>
                                    <p className='about_subtxt'> 
                                        {t('company_about5')} 
                                    </p>
                                </div>
                            </div>
                            <div className={classes.inlineBox__third}>
                                <div>
                                    <div className={classes.imgBox}  >
                                        <img className={classes.imgSource} src={about_img2} alt="about us"/> 
                                    </div>
                                    <div>
                                        <p className='about_subtit'> <b>{t('company_about6')}</b></p>
                                        <p className='about_subtxt'> 
                                            {t('company_about7')} 
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.inlineBox__third}>
                                <div>
                                    <div className={classes.imgBox}  >
                                        <img className={classes.imgSource} src={about_img3} alt="about us"  /> 
                                    </div>
                                    <div>
                                        <p className='about_subtit'> <b>{t('company_about8')} </b></p>
                                        <p className='about_subtxt'> 
                                            {t('company_about9')} 
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div >
                        
                    </div>
                </div>
            </div>
            <div className='color_back_sub d-block' data-aos="fade-up">
                
                {/* 히스토리 */}
                <div >
                    <div className='py-12'>
                        <div style={{textAlign:'center'}}>
                            <h1 className='history_tit'>{t('company_history1')}</h1>
                            <p className='history_sub'> {t('company_history2')} </p>

                            {media960 === true ?

                            <img className={classes.imgHistory} src={img_history_960}alt="about us" />
                            :
                            <img className={classes.imgHistory} src={img_history1}alt="about us" />
                            }
                        </div>
                    </div>

                </div>
            </div>
            <div className='py-12' data-aos="fade-up">
                <div className={classes.teamBox}>
                    
                    {/* team */}
                    <div >
                        <div className='text-center'>
                            <h1 className='team_tit'>{t('company_team1')}</h1>
                            <p className='team_sub'> {t('company_team2')} </p>
                        </div>

                        <div className='text-left '>
                            <div className='team_cover'>
                                <div className='team_sub_cover'>
                                    <div className='team_box'>
                                        <h1 className='team_display'>
                                            <span>{t('team_ceo1-1')}<span className='ms-2 team_name'>{t('team_ceo1')}</span></span>
                                        </h1>
                                        <div className='team_txt pe-lg-4 ps-lg-3 pe-sm-2 ps-sm-2 w-100'>
                                            <li>{t('team_ceo2')}</li>
                                            <li>{t('team_ceo3')}</li>
                                            <li>{t('team_ceo4')}</li>
                                            <li>{t('team_ceo5')}</li>
                                        </div>
                                    </div>

                                    {media720 === true ?
                                    <hr className='m-5'/>
                                    :
                                    <></>
                                    }

                                    <div className='team_box'>
                                        <h1 className='team_display'>
                                            <span>{t('team_cto1-1')}<span className='ms-2 team_name'>{t('team_cto1')}</span></span>
                                        </h1>
                                        <div className='team_txt pe-lg-4 ps-lg-3 pe-sm-0 ps-sm-2 w-100'>
                                            <li>{t('team_cto2')}</li>
                                            <li>{t('team_cto3')}</li>
                                            <li>{t('team_cto4')}</li>
                                            <li>{t('team_cto5')}</li>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div >

                            <hr className='m-5 '/>

                            <div className='my-8'>
                                <div className='team_sub_cover'>
                                    <div className='team_box'>
                                        <h1 className='team_display'>
                                            <span>{t('team_cso1-1')}<span className='ms-2 team_name'>{t('team_cso1')}</span></span>
                                        </h1>
                                        <div className='team_txt pe-lg-4 ps-lg-3 pe-sm-2 ps-sm-2 w-100'>
                                            <li>{t('team_cso2')}</li>
                                            <li>{t('team_cso3')}</li>
                                            <li>{t('team_cso4')}</li>
                                        </div>
                                    </div>
                                    
                                    {media720 === true ?
                                    <hr className='m-5 '/>
                                    :
                                    <></>
                                    }
                                    <div className='team_box'>
                                        <h1 className='team_display'>
                                            <span>{t('team_cmo1-1')}<span className='ms-2 team_name'>{t('team_cmo1')}</span></span>
                                        </h1>
                                        <div className='team_txt pe-lg-4 ps-lg-3 pe-sm-2 ps-sm-2 w-100'>
                                            <li>{t('team_cmo2')}</li>
                                            <li>{t('team_cmo3')}</li>
                                            <li>{t('team_cmo4')}</li>
                                            <li>{t('team_cmo5')}</li>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div >
                        </div>
                    </div>
                
                </div>
            </div>
            <div className='color_back_sub py-15' data-aos="fade-up">
                <div className={classes.mainBox}>
                    {/* awards */}
                    <div>
                        <div className={classes.mainBox__subHeader} >
                            <div className='text-center'>
                                <h1 className='awards_tit'>{t('company_awards1')}</h1>
                                <p className='awards_sub'> {t('company_awards2')} </p>
                            </div>
                        
                            <h4 className='awards_mb mb-4'>
                                <img src={ic_award} className='ic_award me-3'alt="about us"/>
                                <span className='fw-bold awards_txt awards_none me-5'>{t('company_awards10')}</span>
                                <span className='awards_txt txt_space'>{t('company_awards10-1')}</span> 
                            </h4>
                            <h4 className='mb-4'>
                                <img src={ic_award} className='ic_award me-3'alt="about us"/>
                                <span className='fw-bold awards_txt awards_none me-5'>{t('company_awards3')}</span>
                                <span className='awards_txt txt_space'>{t('company_awards3-1')}</span> 
                            </h4>
                            <h4 className='mb-4'>
                                <img src={ic_award} className='ic_award me-3'alt="about us"/>
                                <span className='fw-bold awards_txt awards_none me-5'>{t('company_awards4')}</span>
                                <span className='awards_txt txt_space'>{t('company_awards4-1')}</span>
                            </h4>
                            <h4 className='mb-4'>
                                <img src={ic_award} className='ic_award me-3'alt="about us"/>
                                <span className='fw-bold awards_txt awards_none me-5'>{t('company_awards5')}</span>
                                <span className='awards_txt txt_space'>{t('company_awards5-1')}</span>
                            </h4>
                            <h4 className='mb-4'>
                                <img src={ic_award} className='ic_award me-3'alt="about us"/>
                                <span className='fw-bold awards_txt awards_none me-5'>{t('company_awards6')}</span>
                                <span className='awards_txt txt_space'>{t('company_awards6-1')}</span>
                            </h4>
                            <h4 className='mb-4'>
                                <img src={ic_award} className='ic_award me-3'alt="about us"/>
                                <span className='fw-bold awards_txt awards_none me-5'>{t('company_awards7')}</span>
                                <span className='awards_txt txt_space'>{t('company_awards7-1')}</span>
                            </h4>
                            <h4 className='mb-4'>
                                <img src={ic_award} className='ic_award me-3'alt="about us"/>
                                <span className='fw-bold awards_txt awards_none me-5'>{t('company_awards8')}</span>
                                <span className='awards_txt txt_space'>{t('company_awards8-1')}</span>
                            </h4>
                            <h4 className='mb-4'>
                                <img src={ic_award} className='ic_award me-3'alt="about us"/>
                                <span className='fw-bold awards_txt awards_none me-5'>{t('company_awards9')}</span>
                                <span className='awards_txt txt_space'>{t('company_awards9-1')}</span>
                            </h4>
                        </div>
                    </div>

                </div >
            </div>

            {/* <div > */}
            <div className={classes.mainBox__back} data-aos="fade-up">
                <div className='pt-15'>
                    <div className='text-center'>
                        <h1 className='contet_tit'>{t('company_partner1')}</h1>
                        <p className='contet_sub'> {t('company_partner2')} </p>
                    </div>
                    <div className='partner_cover text-center'>
                        {media720 === true ?
                        <div >
                            <div className='d-flex w-100 justify-content-center mb-9' >
                                <div className='partner_960_under'>
                                    <img src={partner1} className='text-start'alt="about us"></img>
                                </div>
                            
                                <div className='partner_960_under'>
                                    <img src={partner2} alt="about us"></img>
                                </div>
                            </div>
                            <div className='d-flex w-100 justify-content-center mb-9' >
                                <div className='partner_960_under'>
                                    <img src={partner3} alt="about us"></img>
                                </div>
                            
                                <div className='partner_960_under'>
                                    <img src={partner4} alt="about us"></img>
                                </div>
                            </div>
                            <div className='d-flex w-100 justify-content-center mb-9' >
                                <div className='partner_960_under'>
                                    <img src={partner5} alt="about us"></img>
                                </div>
                            
                                <div className='partner_960_under'>
                                    <img src={partner6} alt="about us"></img>
                                </div>
                            </div>
                            <div className='d-flex w-100 justify-content-center mb-9' >
                                <div className='partner_960_under'>
                                    <img src={partner7} alt="about us"></img>
                                </div>
                            
                                <div className='partner_960_under'>
                                    <img src={partner8} alt="about us"></img>
                                </div>
                            </div>
                            <div className='d-flex w-100 justify-content-center mb-9' >
                                <div className='partner_960_under'>
                                    <img src={partner9} alt="about us"></img>
                                </div>
                            
                                <div className='partner_960_under'>
                                    <img src={partner10} alt="about us"></img>
                                </div>
                            </div>
                        </div>
                        :
                        <div >
                            <div className='d-flex w-100 justify-content-center' >
                                <div className='partner_960'>
                                    <img src={partner1} alt="about us"></img>
                                </div>
                            
                                <div className='partner_960'>
                                    <img src={partner2} alt="about us"></img>
                                </div>
                                
                                <div className='partner_960'>
                                    <img src={partner3} alt="about us"></img>
                                </div>
                                <div className='partner_960'>
                                    <img src={partner4} alt="about us"></img>
                                </div>
                                <div className='partner_960'>
                                    <img src={partner5} alt="about us"></img>
                                </div>
                                
                            </div>
                            <div className='d-flex w-100 justify-content-center mt-12'  >
                                <div className='partner_960'>
                                    <img src={partner6} alt="about us"></img>
                                </div>
                                <div className='partner_960'>
                                    <img src={partner7} alt="about us"></img>
                                </div>
                                
                                <div className='partner_960'>
                                    <img src={partner8} alt="about us"></img>
                                </div>
                                <div className='partner_960'>
                                    <img src={partner9} alt="about us"></img>
                                </div>
                                <div className='partner_960'>
                                    <img src={partner10} alt="about us"></img>
                                </div>
                            </div>
                        </div>
                        }
                    </div >
                </div>
            </div>
        </div>
    )
}