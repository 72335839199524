import React from 'react';
import { t } from 'i18next';


export default function Footer({ boool }) {

    return (
        <div className="footer">
            <span>{t('footer_copyright')}</span>
            <span className='mx-2' style={{color: "#A5A5A5"}}>|</span>
            <span>{t('footer_addr')} </span>
            <span className='mx-2' style={{color: "#A5A5A5"}}>|</span>
            <span>{t('footer_mail')}</span>
        </div>
    )
}