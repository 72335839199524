import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { t } from 'i18next';
import media1 from '../icon/ic_media.png';

export const whatImg1 = (str) => {
    let result = ""
    switch (str){
        case "en" :
            result = "https://www.youtube.com/embed/Vb23O5TQisU?si=A_g-ZuUbzKYAStM5"; 
            break;
        case "ko" :
            result = "https://www.youtube.com/embed/4C15kFZAGBE?si=OjaAo9X48w86LMa-"; //
            break;
        default:
            result = "https://www.youtube.com/embed/Vb23O5TQisU?si=A_g-ZuUbzKYAStM5";
            break;
        
    }
    return result
}

/**
 * xs, extra-small: 0px
    sm, small: 600px
    md, medium: 960px
    lg, large: 1280px
    xl, extra-large: 1920px
 */
const useStyles = makeStyles((theme) => ({

    mainBox: {
        maxWidth: "1200px",
        width: "70%",
        margin: "auto",
        marginTop: "80px",
        marginBottom: "150px",
        fontFamily: "NanumSquare",
        lineHeight: "1.88",
        
        [theme.breakpoints.down('md')]: { 
            width: "80%",
        },
        [theme.breakpoints.down('xs')]: { 
            width: "90%",
        },
    },
    mainBox__header: {
        margin: "0",
        [theme.breakpoints.down('xs')]: {
            fontSize: "30px",
        },
    },
    mainBox__content: {
        marginTop: "4.5%",
        width: "105%",
        maxWidth: "1300px",
        marginLeft: "-25px",
        [theme.breakpoints.down('xs')]: { 
            marginTop: "4.5%",
            width: "100%",
            margin: "auto",
        },
    },
    mainBox__subHeader: {

        maxWidth: "85%",
        width: "100%",
        margin:"auto",
        marginTop: "75px",

        [theme.breakpoints.down('md')]: { 
            maxWidth:"100%",
        },
        [theme.breakpoints.down('xs')]: { 
            width: "90%",
            textAlign: "left",
        },
    },
    content: {
        display: "flex",
        flexDirection: "row",
        marginTop: "9rem",
        [theme.breakpoints.down('xs')]: { 
            flexDirection: "column",
        },
    },
    content__three: {
        maxWidth: "25%",
        width: "100%",
        [theme.breakpoints.down('xs')]: { 
            maxWidth: "100%",
        },
    },
    content__three__div: {
        marginTop: "2rem",
        fontSize: "1.34rem",
        marginLeft: "25%",
        fontWeight: "800",
        whiteSpace: "pre-line",
        lineHeight: "1.5",
        [theme.breakpoints.down('sm')]: {
            marginLeft: "-10px",
        },
        [theme.breakpoints.down('xs')]: { 
            textAlign: "center",
            marginLeft: "0",
            whiteSpace: "normal",
        },
    },
    content__three__hr: {
        width: "110%",
        marginLeft: "-30px",
        [theme.breakpoints.down('xs')]: { 
            display: "none",
        },
    },
    content__seven: {
        maxWidth: "70%",
        width: "100%",
        marginLeft: "4.5rem",
        [theme.breakpoints.down('xs')]: { 
            maxWidth: "100%",
            marginLeft: "0",
        },
    },
    
    content__seven__h4: {
        
    },
    content__seven__h4__hover: {
        marginTop: "2rem",
        fontSize: "1.04rem",
        [theme.breakpoints.down('xs')]: {
            marginLeft: "0",
            fontSize: ".0.95rem",
        },
    },
    content__seven__p: {
        marginTop: "0.5rem",
        fontSize: "0.95rem",
        fontWeight: "200",
        [theme.breakpoints.down('xs')]: {
            margin: "auto",
            fontSize: ".90rem",
            textAlign: "left",
        },
    },
    content__seven__hr: {
        width: "102%",
        [theme.breakpoints.down('xs')]: {
            marginTop: "30px",
        },
    },
    h4Style: {
        fontSize: "3.4rem",
        fontFamily: "GmarketSans",
        fontWeight: "bold",
        lineHeight: "1.2",
        letterSpacing: "-0.98px",
        color: "#e1e1e1",
        [theme.breakpoints.down('xs')]: {
            fontSize: "2.7rem",
        },
    },
}));

export default function Media() {
    const classes = useStyles();
    sessionStorage.setItem('imgOn', false);

    const video = whatImg1(t('lang'));

    return (
        <div >
            <div className="sub-bg-wrap-media sub-bg-wrap fs_32">
                <div className="content">
                    <span>{t('media0')}</span>
                    
                </div>
            </div>
            <div className={classes.mainBox} >
                <div data-aos="fade-up" className='media_video'>
                    <iframe width="100%" height="560" src={video} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </div>

                <div className={classes.mainBox__subHeader} data-aos="fade-up" >
                    <h4 className='media'>
                        <img src={media1} className='ic_media me-3' alt='media'/>
                        
                        <a rel="noreferrer" href='https://n.news.naver.com/mnews/article/008/0004966770?sid=101' target='_blank' >
                        <span className='media_txt'>{t('media10-1')}</span>
                        </a> 
                    </h4>
                    <h4 className='media'>
                        <img src={media1} className='ic_media me-3' alt='media'/>
                        
                        <a rel="noreferrer" href='https://platum.kr/archives/201762' target='_blank' >
                        <span className='media_txt'>{t('media1-1')}</span>
                        </a> 
                    </h4>
                    <h4 className='media'>
                    <img src={media1} className='ic_media me-3' alt='media'/>
                    
                        <a rel="noreferrer" href='https://www.dailian.co.kr/news/view/1185777/?sc=Naver' target='_blank' >
                        <span className='media_txt'>{t('media2-1')}</span>
                        </a>
                    </h4>
                    <h4 className='media'>
                    <img src={media1} className='ic_media me-3' alt='media'/>
                    
                        <a rel="noreferrer" href='https://www.dkilbo.com/news/articleView.html?idxno=385241' target='_blank' >
                        <span className='media_txt'>{t('media3-1')}</span>
                        </a></h4>
                    <h4 className='media'>
                    <img src={media1} className='ic_media me-3' alt='media'/>
                    
                        <a rel="noreferrer" href='https://www.itbiznews.com/news/articleView.html?idxno=82890' target='_blank' >
                        <span className='media_txt'>{t('media4-1')} </span>
                        </a></h4>
                    <h4 className='media'>
                    <img src={media1} className='ic_media me-3' alt='media'/>
                    
                        <a rel="noreferrer" href='https://newsis.com/view/?id=NISX20221109_0002079080&cID=10701&pID=10700' target='_blank' >
                        <span className='media_txt'>{t('media5-1')} </span>
                        </a></h4>
                    <h4 className='media'>
                    <img src={media1} className='ic_media me-3' alt='media'/>
                    
                        <a rel="noreferrer" href='https://kr.aving.net/news/articleView.html?idxno=1773270' target='_blank' >
                        <span className='media_txt'>{t('media6-1')} </span>
                        </a></h4>
                    <h4 className='media'>
                    <img src={media1} className='ic_media me-3' alt='media'/>
                    
                        <a rel="noreferrer" href='https://www.econovill.com/news/articleView.html?idxno=360176' target='_blank' >
                        <span className='media_txt'>{t('media7-1')} </span>
                        </a></h4>
                    <h4 className='media'>
                    <img src={media1} className='ic_media me-3' alt='media'/>
                    
                        <a rel="noreferrer" href='https://www.ekn.kr/web/view.php?key=20210927010004028' target='_blank' >
                        <span className='media_txt'>{t('media8-1')} </span>
                        </a></h4>
                </div>
        
            </div >
        </div>
    )
}