import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import useMediaQuery from '@material-ui/core/useMediaQuery';
import '../style/style.css';
import { t } from 'i18next';
import { Map, MapMarker } from 'react-kakao-maps-sdk';
import emailjs from '@emailjs/browser';

export const nameMSG = (str) => {
    let result = ""
    switch (str){
        case "en" :
            result = "Please enter your name";
            break;
        case "ko" :
            result = "이름을 입력해주세요.";
            break;
        default:
            result = "Please enter your name";
            break;   
    }
    return result
}
export const telMSG = (str) => {
    let result = ""
    switch (str){
        case "en" :
            result = "Please enter your phone number.";
            break;
        case "ko" :
            result = "연락처를 입력해주세요.";
            break;
        default:
            result = "Please enter your phone number.";
            break;   
    }
    return result
}
export const companyMSG = (str) => {
    let result = ""
    switch (str){
        case "en" :
            result = "Please enter your company name.";
            break;
        case "ko" :
            result = "회사명을 입력해주세요.";
            break;
        default:
            result = "Please enter your company name.";
            break;   
    }
    return result
}
export const emailMSG = (str) => {
    let result = ""
    switch (str){
        case "en" :
            result = "Please enter your E-mail.";
            break;
        case "ko" :
            result = "이메일을 입력해주세요.";
            break;
        default:
            result = "Please enter your E-mail.";
            break;   
    }
    return result
}
export const emailMSG2 = (str) => {
    let result = ""
    switch (str){
        case "en" :
            result = "The email format is incorrect. Please enter again.";
            break;
        case "ko" :
            result = "이메일 형식이 올바르지 않습니다. 다시 입력해주세요.";
            break;
        default:
            result = "The email format is incorrect. Please enter again.";
            break;   
    }
    return result
}
export const msgMSG = (str) => {
    let result = ""
    switch (str){
        case "en" :
            result = "Please enter details.";
            break;
        case "ko" :
            result = "문의사항을 작성해주세요.";
            break;
        default:
            result = "Please enter details.";
            break;   
    }
    return result
}

export const msgSendSucess = (str) => {
    let result = ""
    switch (str){
        case "en" :
            result = "Mail has been sent successfully.";
            break;
        case "ko" :
            result = "메일이 전송되었습니다.";
            break;
        default:
            result = "Mail has been sent successfully.";
            break;   
    }
    return result
}
export const msgSendFail = (str) => {
    let result = ""
    switch (str){
        case "en" :
            result = "Mail delivery failed. please try again.";
            break;
        case "ko" :
            result = "메일 전송에 실패했습니다. 다시 시도해 주세요.";
            break;
        default:
            result = "Mail delivery failed. please try again.";
            break;   
    }
    return result
}

/**
 * xs, extra-small: 0px
    sm, small: 600px
    md, medium: 960px
    lg, large: 1280px
    xl, extra-large: 1920px
 */
const useStyles = makeStyles((theme) => ({

    mainBox: {
        maxWidth: "1200px",
        width: "70%",
        margin: "auto",
        marginTop: "80px",
        marginBottom: "150px",
        fontFamily: "NanumSquare",
        lineHeight: "1.88",
        [theme.breakpoints.down('md')]: { 
            width: "70%",
        },
        [theme.breakpoints.down('sm')]: { 
            width: "80%",
        },
        [theme.breakpoints.down('xs')]: { 
            width: "90%",
            margin: "auto",
            textAlign: "center",

        },
    },

      mapBox: {
        maxWidth: "1000px",
        width: "100%",
        margin: "auto",
        fontFamily: "NanumSquare",
        lineHeight: "1.88",
        [theme.breakpoints.down('xs')]: { 
            width: "100%",
            textAlign: "center",
            margin: "auto",
        },
    },

    mapDisply: {
        display:"flex",
        [theme.breakpoints.down('xs')]: { 
            display:"block",
            margin: "auto",
        },
    },

    mapStyle: {
        width: '450px', 
        height: '400px',
        [theme.breakpoints.down('sm')]: { 
            width: '300px', 
            height: '225px',
            margin: "auto",
            marginBottom: "25px",
        },
    },

}));

export default function Contact() {
    const classes = useStyles();
    sessionStorage.setItem('imgOn', false);

    const nameAlert = nameMSG(t('lang'));
    const telAlert = telMSG(t('lang'));
    const companyAlert = companyMSG(t('lang'));
    const emailAlert = emailMSG(t('lang'));
    const emailAlert2 = emailMSG2(t('lang'));
    const msgAlert = msgMSG(t('lang'));
    const sendSucess = msgSendSucess(t('lang'));
    const sendFail = msgSendFail(t('lang'));

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        
        const regex = /^[0-9a-zA-Z]([-_]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;
        
        if (form.current.name.value.trim() === "") {
            alert(nameAlert)
        } else if (form.current.tel.value.trim() === "") {
            alert(telAlert)
        } else if (form.current.company.value.trim() === "") {
            alert(companyAlert)
        } else if (form.current.email.value.trim() === "" ) {
            alert(emailAlert)
        } else if (form.current.message.value.trim() === "") {
            alert(msgAlert)
        } else if (regex.test(form.current.email.value.trim()) === false) {
            alert(emailAlert2)
        } else {
            emailjs.sendForm('service_0sl3e7q', 'template_huqpspo', form.current, 'QQ4-PXkBD0XhmbLhX')
            .then((result) => {
                if (result.text === "OK") {
                    alert(sendSucess)

                    form.current.name.value = ""
                    form.current.tel.value = ""
                    form.current.company.value = ""
                    form.current.email.value = ""
                    form.current.message.value = ""

                } else 
                    alert(sendFail)
            }, (error) => {
                alert(sendFail)
            });
            
        }
    };

    return (
        <div >
            <div className="sub-bg-wrap-contact sub-bg-wrap fs_32">
                <div className="content">
                    <span>{t('contact0')}</span>
                    
                </div>
            </div>
            <div className={classes.mainBox}>

                <div data-aos="fade-up">
                    <div className='text-center my-5 '>
                        <h3 className='contact_tit'>{t('contact_form')}</h3>
                    </div>

                    <form ref={form} onSubmit={sendEmail} className='form' >

                        <div className='pt-5'>
                            <input type="text" name="name" className='form-control form_field w_form' placeholder={t('contact_form_name')} autoFocus={true} required />
                        </div>
                        <div>
                            <input type="number" name="tel" className='form-control form_field w_form' placeholder={t('contact_form_tel')}  required />
                        </div>
                        <div>
                            <input type="text" name="company" className='form-control form_field w_form' placeholder={t('contact_form_company')}  required />
                        </div>
                        <div>
                            <input type="email" name="email" className='form-control form_field w_form' placeholder={t('contact_form_email')}  required />
                        </div>
                        <div>
                            <textarea name="message" className='form-control form_field w_form h_200' placeholder={t('contact_form_msg')}  required />
                        </div>
                        <input type="submit" value={t('contact_form_send')} className='mx-auto mt-5 form-control w-20 form_submit' />
                    </form>
                </div>

                <hr/>

                <div className={classes.mapBox}>

                    <div data-aos="fade-up" className='w-100'>

                        <div className='my-5'>
                            <h1 className='map_tit'>{t('contact_map1')}</h1>
                            
                        </div>
                        <div className={classes.mapDisply}>

                            <Map 
                                center={{ lat: 37.5435456, lng: 127.0436285}}
                                className={classes.mapStyle}
                                level={3}>
                                <MapMarker position={{ lat: 37.5435456, lng: 127.0436285}}/>  
                            </Map>
                            
                            <div className='map_content'>
                                <span className='contact_txt'>{t('contact_map2')}</span><br/>
                                <span className='contact_txt'>{t('contact_map3')}</span><br/>
                                <span className='contact_txt'>{t('contact_map4')}</span><br/>
                                <span className='contact_txt'>{t('contact_map5')}</span>
                            </div>
                        </div>
                    </div>
                </div>
        
            </div >
        </div>
    )
}