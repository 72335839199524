//import logo from './logo.svg';
import './App.css';
import './style/test.css';
import bgColor from './style/Background.module.css';
import Bar from './Component/Bar';
import Footer from './Component/Footer';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import MainPage from './Route/Main'
import Introduction from './Route/Introduction';
import Research from './Route/Research';
import { useEffect, useState } from 'react';
import { TransitionGroup } from "react-transition-group";
import Media from './Route/Media';
import Contact from './Route/Contact';
import Service from './Route/Service';
import AOS from 'aos';
import "aos/dist/aos.css";

function App() {
  const handleTop = () => {
    window.scrollTo({
      top: "0",
      behavior: "smooth",
    })
  }
  const handleBack = () => {
    sessionStorage.setItem('imgOn', false);
    setChecked(!checked);
    handleTop();
    setImgOn(false);
  }

  const onBack = () => {
    sessionStorage.setItem('imgOn', true);
    setChecked(!checked);
    handleTop();

    setImgOn(true);
  }
  let [imgOn, setImgOn] = useState(true);
  let [backgroundset, setBackgroundset] = useState(bgColor.Back);
  let [checked, setChecked] = useState(true);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  useEffect(() => {
  
    if (sessionStorage.getItem('imgOn') === null) {
      // console.log(sessionStorage.getItem('imgOn') + "처음");
      //여기서  seesionStorage.setItem 해줘야함
    } else {
      if (!imgOn || sessionStorage.getItem('imgOn') === 'false') {
        setBackgroundset(bgColor.BackChange);
        setImgOn(false);
      }
      else {
        setBackgroundset(bgColor.Back);
      }
    }
  }, [imgOn]);

  return (
    <div className={backgroundset} id="test">
      <div className="App">
        <BrowserRouter>
          <Bar sel={handleBack} onBack={onBack} boool={imgOn} check={checked} />
          <TransitionGroup>
            <Switch>

{/* ----------------------------------------------230818------------------------------------------------------ */}
              <Route exact path='/' element={<MainPage />} component={MainPage} onChange={handleBack}></Route>
              <Route exact path='/service' element={<Service earch />} component={Service}></Route> {/* service */}
              <Route exact path='/technology' element={<Research earch />} component={Research}></Route> {/* technology */}
              <Route exact path='/company' element={<Introduction />} component={Introduction}></Route> {/* company */}
              <Route exact path='/media' element={<Media />} component={Media}></Route> {/* media */}
              <Route exact path='/contact' element={<Contact />} component={Contact}></Route> {/* contact */}
{/* ----------------------------------------------230818------------------------------------------------------ */}

            </Switch>
          </TransitionGroup>
          <Footer boool={imgOn} />

        </BrowserRouter>
      </div>
    </div >
  );
}

export default App;
